import styled, {css} from "styled-components";

import ReactPlayer from "react-player";

import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

const ButtonWrapper = styled.div`
    position: fixed;
    width: 100vw;
    max-width: 720px;
    bottom: 0;
`;
const ButtonBox = styled.div`
    //margin: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 20px;
    //padding: 10px;
`;
const OrderButton = styled.button`
    border: 0;
    //padding: 10px;
    //flex: 1;
    //margin: 5px;
    border-radius: 7px;
    color: white;
    font-weight: bolder;
    //max-width: 200px;
    width: 100%;
    height: 50px;
    font-size: 25px;
    margin: 20px;
`;
const VideoBox = styled.div`
    position: relative;
    height: 100%;
    width: 100%;

    .react-player video {
        object-fit: contain;
    }
`;
const ModalContainer = styled.div`
    position: fixed;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    background: black;
    z-index: 999;
    overflow: hidden;
    
    opacity: 0;
    transition: all 500ms;
`

const ModalWrapper = styled.div`
    width: 100%;
    max-width: 720px;
    position: relative;
    margin: auto;
    height: 100%;
`
const VideoModal = ({onClose, isOpen}) => {
    const {t: uiTranslation} = useTranslation("ui");
    const {i18n} = useTranslation("scripts");
    const [hide, setHide] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setHide(!isOpen);
        }, 200);
    }, [isOpen]);
    console.info(isOpen);

    const hideBeforeClose = () => {
        setHide(true);
        setTimeout(() => {
            onClose();
        }, 500);
    }
    return (
        isOpen && <ModalContainer style={{ opacity: hide ? 0 : 1}}>
                <ModalWrapper>
                    <VideoBox>
                        <ReactPlayer
                            className="react-player"
                            url={`/video/${i18n.language}/short.mp4`} width={'100%'}
                            height={'100%'}
                            controls={false} playsinline playing loop
                        />
                    </VideoBox>
                    <ButtonWrapper>
                        <ButtonBox>
                            <OrderButton style={{
                                color: "#666666",
                                border: "1px solid #666666",
                                background: "white",
                                fontSize: "16px"
                            }}
                                         onClick={hideBeforeClose}>{uiTranslation("CLOSE")}</OrderButton>
                        </ButtonBox>
                    </ButtonWrapper>
                </ModalWrapper>
            </ModalContainer>
    )
}


export default VideoModal;

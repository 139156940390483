import styled from "styled-components";
import colors from "../styles/colors";

import ReactPlayer from "react-player";

import {useTranslation} from "react-i18next";

import parse from 'html-react-parser';
import chevronDown from '../assets/images/icons/chevronDown.svg'
import chevronUp from '../assets/images/icons/chevronUp.svg'
import {useEffect, useState} from "react";

const Wrapper = styled.div`
  width: 100%;
  min-height: 80vh;
  background: ${colors.tabMenu};
`;

const ListBox = styled.div`
  margin-top: 10px;
`;

const ModalTitle = styled.h1`
  font-size: 25px;
  margin-top:0px;
  height: 20px;
  color: #333333;
  text-align: center;
`;

const ModalDescOuter =  styled.div`
  padding-top : 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
`;

const ModalDescription = styled.div`
  padding-top: 10px;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;

`;

const DescriptionButton = styled.div`
  display: flex;
  padding: 4px;
  border-radius: 4px;
  justify-content: center;
  img{
    height: 8px;
  }
`;

const ModalOpenDescription = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
`;

const ButtonWrapper = styled.div`
  position: fixed;
  width: 100vw;
  //height: 80px;
  padding-right: 40px;
  
  max-width: 720px;
  bottom: 0;
`;
const ButtonBox = styled.div`
  //margin: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 20px;
  //padding: 10px;
`;
const OrderButton = styled.button`
  border: 0;
  //padding: 10px;
  //flex: 1;
  //margin: 5px;
  border-radius: 7px;
  color: white;
  font-weight: bolder;
  //max-width: 200px;
  width: 100%;
  height: 50px;
  font-size: 25px;
`;
const VideoBox = styled.div`
  //min-height: 150px;
  //margin: 10px;
  position: relative;
  //padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  aspect-ratio: 1920/1080;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const PriceInfoBox = styled.div`
  width: 100%;
  height: 110px;
  border-bottom: 1.5px solid #B1B1B1;
  display: flex;
  justify-content: space-between;
`
const PriceBox = styled.div`
  width: 60%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`
const PriceInnerContent = styled.div`
  width: 100%;
  height: 25%;
  display: flex;
  align-items: center;
`


const PriceCalBox = styled.div`
  width: 35%;
  height: 100%;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  
`
const CalBoxOuter = styled.table`
  width: 100%;
  height: 100%;
  border: 1px solid #CCCCCC;
  border-collapse: collapse;
  //display: flex;
  flex-direction: row;

`

const CalBoxInner = styled.td`
  width: 50%;
  height: 100%;
  border: 1px solid #CCCCCC;
  border-collapse: collapse;
  text-align:center;
  vertical-align: middle;
  font-size: 20px;
`
const CountBox = styled.div`
  width: 100%;
  height: 50%;
  border-collapse: collapse;
  display: flex;
  /* align-content: center; */
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  padding-bottom: 75px;
    padding-left: 20px;
    padding-right: 20px;
`

const TotPriceBox = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 15px;
  justify-content: flex-end;
  font-size: 17px;
  font-weight: bold;
  align-items: flex-end;
  gap: 12px;
  
  p, div {
    padding: 0;
    margin: 0;
    line-height: 1;
  }
`

const AllPrice = styled.div`
  font-size: 25px;
  font-weight: bold;
`
const ModalWrapper = styled.div`
  width: 100%;
  max-width: 720px;
  position: relative;
  margin: auto;
  height: 100%;
`
const InnerDiv= styled.div`
  max-width: 720px;
  margin: auto;
`

const VideoModal = ({onClose, speed}) => {
  const {t: uiTranslation} = useTranslation("ui");
  const {t: scriptTranslation, i18n} = useTranslation("scripts");
  const [descOpen, setDescOpen] = useState(false)

  console.info(i18n.language);
  return (
    <ModalContainer>
      <ModalWrapper>
        <ModalTitle>
        </ModalTitle>
        <VideoBox>
          <ReactPlayer
            className="react-player"
            url={`/video/${i18n.language}/1x.mp4`} width={'100%'} height={'100%'}
            controls playsinline playing loop
          />
        </VideoBox>
        <ModalDescOuter>
          {!descOpen ?
            <>
              <ModalDescription className='close'>
                    {parse(scriptTranslation('SCRIPT'))}
               </ModalDescription>
              <DescriptionButton onClick={()=>setDescOpen(true)}>
                  <img src={chevronDown}/>
              </DescriptionButton>
            </>
            :<>
              <ModalOpenDescription className='open'>
                {parse(scriptTranslation('SCRIPT'))}
              </ModalOpenDescription>
              <DescriptionButton onClick={()=>setDescOpen(false)}>
                <img src={chevronUp}/>
              </DescriptionButton>
            </>}

        </ModalDescOuter>
        <ButtonWrapper>
          <ButtonBox>
            <OrderButton style={{color:"#666666", border:"1px solid #666666", background:"white", fontSize:"16px"}}
                         onClick={onClose}>{uiTranslation("CLOSE")}</OrderButton>
          </ButtonBox>
        </ButtonWrapper>
      </ModalWrapper>
    </ModalContainer>
  )
}


export default VideoModal;

import i18n from "i18next";
import {initReactI18next} from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
import en from './assets/lang/en.json';
import ko from './assets/lang/ko.json';
import cn from './assets/lang/cn.json';
import In from './assets/lang/in.json';
import jp from './assets/lang/jp.json';
import vn from './assets/lang/vn.json';
import th from './assets/lang/th.json';


const resources = {
    ...en,
    ...ko,
    ...cn,
    ...In,
    ...jp,
    ...vn,
  ...th,
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
